import Button from '@berlitz/button'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { WidgetCard, WidgetFooter } from '../Common'
import { ImageLabel, ImageWrapper, Wrapper } from './style'
import {
  learningPathSelector,
  registrationSelector,
  userProfileSelector,
} from '@components/ActiveState/activeStateSlice'
import { SelectElectiveModal } from '@components/Modal/SelectElectiveModal'
import Spacer from '@berlitz/spacer'

const UnbookedElectives: React.FC = (props) => {
  const registrationCanAddMoreElective = useSelector(registrationSelector.selectCanAddMoreElective)
  const mainMaterial = useSelector(registrationSelector.selectCoreMaterial)
  const userProfile = useSelector(userProfileSelector.selectUserProfile)
  const learningPath = useSelector(learningPathSelector.selectLearningPath)
  const electives = useSelector(registrationSelector.selectElectiveMaterials)
  const isElectiveMutli = useSelector(registrationSelector.selectIsElectiveMulti)

  const [open, setOpen] = useState(false)

  /**
   * We don't need to display this widget if one if the following rule is met
   * - if mainMaterial is empty
   * - if the registeredElective is 3
   * - if the electives is empty
   * - if isElectiveMutli is false
   */
  if (!mainMaterial || !registrationCanAddMoreElective || (electives || []).length === 0 || !isElectiveMutli) {
    return null
  }

  return (
    <WidgetCard dataPermission={props['data-c-permission']}>
      <Wrapper>
        <ImageWrapper>
          <ImageLabel>
            <FormattedMessage id="You can select up to 3 electives." />
            <Spacer size="md" />
          </ImageLabel>

          <div>
            <img src="/images/image-welcome.svg" alt="You can select up to 3 electives." width={'100%'} />
          </div>
        </ImageWrapper>

        <Spacer />
      </Wrapper>

      <WidgetFooter>
        <Button
          onClick={() => {
            localStorage.setItem(learningPath.tempFlags.SelectElective, 'true')
            setOpen(true)
          }}
        >
          <FormattedMessage id="Select elective" />
        </Button>
      </WidgetFooter>
      <SelectElectiveModal
        MainMaterialId={mainMaterial?.MaterialId || ''}
        StudentProfileId={userProfile?.SFId || ''}
        Path={learningPath}
        open={open}
        onClose={() => setOpen(false)}
      />
    </WidgetCard>
  )
}

export default UnbookedElectives
