import { H2 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Stream } from '@cloudflare/stream-react'
import Spinner from '@berlitz/spinner'
import { InlineSpinner, Overrides } from './style'
import VideoPlayer from '@berlitzplatforms/micro.ui.video-player'
import MenuTab, { MenuTabItem } from '@components/MenuTab'
import { usePortalFeatures } from '@components/RoleBasedView'

export const MenuTabWrapper = styled.div`
  li {
    cursor: pointer;
  }
`

export const VideoWrapper = styled.div`
  video:focus {
    outline: none;
  }
`

interface IVideoModalSrc {
  url: string
  title: string
}

interface IVideoModal {
  open: boolean
  onClose: () => void
  title?: string
  src: string | IVideoModalSrc[]
  width?: string
  height?: string
  hideControls?: boolean
  playing?: boolean
  onPlayerLoaded?: (loaded: boolean) => void
  withDownloadLink?: boolean
}

const SourceVideoPlayer: React.FC<Omit<IVideoModal, 'open' | 'onClose' | 'src'> & { src: string }> = ({
  src,
  height = '500px',
  width = '100%',
  hideControls,
  playing,
  onPlayerLoaded = () => {},
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const isCloudflare = src.includes('cloudflare')
  const isDacast = src.includes('dacast')

  let source = ''
  if (isCloudflare) {
    source = src.split('/').pop() ?? ''
  } else if (isDacast) {
    source = src.split('/').pop() ?? ''
    const chunks = src.split('/').slice(-3)
    source = [chunks[1], chunks[0], chunks[2]].join('-')
  } else {
    source = src
  }

  return (
    <VideoWrapper>
      {!playerLoaded && (
        <InlineSpinner>
          <Spinner />
        </InlineSpinner>
      )}

      {isCloudflare && (
        <Stream
          src={source}
          width={width}
          height={height}
          controls={!hideControls}
          autoplay={playing}
          onLoadedData={() => {
            setPlayerLoaded(true)
            onPlayerLoaded(true)
          }}
        />
      )}

      {isDacast && (
        <Overrides width={width} height={height}>
          <VideoPlayer
            regularPlayer
            controls
            type="dacast"
            url={source}
            playing={playing}
            onReady={() => {
              setPlayerLoaded(true)
              onPlayerLoaded(true)
            }}
          />
        </Overrides>
      )}

      {!isCloudflare && !isDacast && (
        <ReactPlayer
          url={source}
          width={width}
          height={height}
          controls={!hideControls}
          playing={playing}
          onReady={() => {
            setPlayerLoaded(true)
            onPlayerLoaded(true)
          }}
        />
      )}
    </VideoWrapper>
  )
}

const VideoModal: React.FC<IVideoModal> = ({ open, onClose, title, src, hideControls, playing, withDownloadLink }) => {
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const { state } = usePortalFeatures({
    configId: 'c::mymedia::recording::download-link',
  })

  const hasMultipleSrcs = Array.isArray(src) && src.length > 1

  // Check if the device is iOS (no download attribute)
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const showDownloadBtn = withDownloadLink && state.allowed && isIos

  return (
    <Modal
      open={open}
      size="lg"
      onClose={() => {
        setPlayerLoaded(false)
        setActiveTab(1)
        onClose()
      }}
    >
      {playerLoaded && hasMultipleSrcs ? (
        <MenuTabWrapper>
          <MenuTab activeTab={activeTab}>
            <>
              {title ? (
                <MenuTabItem>
                  <div
                    onClick={() => {
                      setActiveTab(1)
                    }}
                  >
                    <FormattedMessage id={title} />
                  </div>
                </MenuTabItem>
              ) : null}
              <MenuTabItem>
                <div
                  onClick={() => {
                    setActiveTab(2)
                  }}
                >
                  <FormattedMessage id="Group Orientation" />
                </div>
              </MenuTabItem>
            </>
          </MenuTab>

          <Spacer />
        </MenuTabWrapper>
      ) : null}

      {hasMultipleSrcs
        ? src.map(({ url }, i) =>
            activeTab === i + 1 ? (
              <SourceVideoPlayer
                key={url}
                src={url}
                height="500px"
                width="100%"
                hideControls={hideControls}
                playing={playing}
                onPlayerLoaded={() => setPlayerLoaded(true)}
              />
            ) : null
          )
        : null}

      {typeof src === 'string' && !hasMultipleSrcs ? (
        <SourceVideoPlayer
          src={src}
          height="500px"
          width="100%"
          hideControls={hideControls}
          playing={playing}
          onPlayerLoaded={() => setPlayerLoaded(true)}
        />
      ) : null}

      {!hasMultipleSrcs ? <Spacer size="md" /> : null}
      {showDownloadBtn && playerLoaded && title && typeof src === 'string' && !hasMultipleSrcs ? (
        <a href={src} download={`${title}.mp4`} style={{ float: 'right' }}>
          <FormattedMessage id="Download recording" />
        </a>
      ) : null}

      {playerLoaded && title && !hasMultipleSrcs && (
        <H2 disableMargin>
          <FormattedMessage id={title} />
        </H2>
      )}

      {!hasMultipleSrcs ? <Spacer size="md" /> : null}
    </Modal>
  )
}

export default VideoModal
