import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
  }
`

export const Icon = styled.div`
  padding: ${({ theme }) => theme.space.lg};
  border-radius: ${({ theme }) => theme.layout.xxxl};
  background-color: ${({ theme }) => theme.palette.red10};
  margin-bottom: ${({ theme }) => theme.space.md};

  svg {
    font-size: ${({ theme }) => theme.fontSize.xl};
    color: ${({ theme }) => theme.palette.red100};
  }
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.space.md};
  text-align: center;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.space.sm}`};
  text-align: center;

  img {
    width: 166px;
  }

  button {
    padding-top: ${({ theme }) => theme.space.xxs};
    padding-bottom: ${({ theme }) => theme.space.xxs};
  }
`

export const ImageLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.charcoal};
`
