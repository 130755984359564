import {
  MyAccount,
  MyCalendar,
  NextLesson,
  Notifications,
  Support,
  UnbookedLessons,
  PromoteFMI,
} from '@components/Widgets'
import UnbookedElectives from '@components/Widgets/UnbookedElectives'
import React from 'react'

const PortalsWidgets = [
  {
    id: 1,
    order: 3,
    component: <MyCalendar />,
    configId: 'c::dashboardWidget::MyCalendar',
  },
  {
    id: 2,
    order: 6,
    component: <MyAccount />,
    configId: 'c::dashboardWidget::MyAccount',
  },
  {
    id: 3,
    order: 7,
    component: <Support />,
    configId: 'c::dashboardWidget::Support',
  },
  {
    id: 4,
    order: 1,
    component: <NextLesson />,
    configId: 'c::dashboardWidget::NextLesson',
  },
  {
    id: 5,
    order: 2,
    component: <UnbookedLessons />,
    configId: 'c::dashboardWidget::UnbookedLessons',
  },
  {
    id: 6,
    order: 4,
    component: <UnbookedElectives />,
    configId: 'c::dashboardWidget::SelectElectives',
  },
  {
    id: 7,
    order: 8,
    component: <Notifications />,
    configId: 'c::dashboardWidget::Notifications',
  },
  {
    id: 8,
    order: 1,
    component: <PromoteFMI />,
    configId: 'c::dashboardWidget::PromoteFMI',
  },
]

export default PortalsWidgets
