import LPCertificateCard from '@berlitzplatforms/micro.ui.lp-certificate-card'
import LPCTCard, { LPCTCardProps } from '@berlitzplatforms/micro.ui.lpct-card/lib'
import PortalLearningPath from '@classes/LearningPath'
import PortalLearningPaths from '@classes/LearningPaths'
import PortalStudentProfile from '@classes/StudentProfile'
import { LoadingModal, WarningModal } from '@components/Modal'
import RoleBasedView from '@components/RoleBasedView'
import { WidgetCard } from '@components/Widgets/Common'
import useCTCardState from '@hooks/useCTCardState'
import useEnsureNoDisplayedModal from '@hooks/useEnsureNoDisplayedModal'
import { StoreProps } from '@interfaces/StoreState'
import PortalsWidgets from '@layouts/Dashboard/Layout/widgets'
import orderBy from 'lodash/orderBy'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Row } from 'react-styled-flexboxgrid'
import CTWidgetLoader from './CTWidgetLoader'
import { LpCtCardOverride, LpCtCertCardOverride } from './style'
import { AchievementInfos } from '@interfaces/Achievements'
import { CertificateTypes, PROGRAM_TYPES } from '@utils/constants'
import useCTPermission from '@hooks/useCTPermission'

interface DashboardWidgetSectionProps {
  LearningPaths: PortalLearningPaths
  Achievements: AchievementInfos[]
  isLoading: boolean
  hasError: any
}

interface Widget {
  id: number
  order: number
  component: JSX.Element
  configId: string
}

const LPCertCard = (intl, studentProfile, configId = '') => {
  return (
    <WidgetCard dataPermission={configId}>
      <LpCtCertCardOverride>
        <LPCertificateCard
          title={intl.formatMessage({
            id: 'Certificate of Achievement',
            defaultMessage: 'Certificate of Achievement',
          })}
          subtitle={intl.formatMessage(
            {
              id: 'Congratulations {name}!',
              defaultMessage: 'Congratulations {name}!',
            },
            {
              name: studentProfile.student.FirstName,
            }
          )}
          onBtnClick={() => {
            Router.push('/settings/certificates')
          }}
          btnLabel={intl.formatMessage({ id: 'View', defaultMessage: 'View' })}
        />
      </LpCtCertCardOverride>
    </WidgetCard>
  )
}

const LPParticipationCert = (intl, studentProfile, configId = '') => {
  return (
    <WidgetCard dataPermission={configId}>
      <LpCtCertCardOverride>
        <LPCertificateCard
          title={intl.formatMessage({
            id: 'Certificate of Participation',
            defaultMessage: 'Certificate of Participation',
          })}
          subtitle={intl.formatMessage(
            {
              id: 'Well Done {name}!',
              defaultMessage: 'Well Done {name}!',
            },
            {
              name: studentProfile.student.FirstName,
            }
          )}
          onBtnClick={() => {
            Router.push('/settings/certificates')
          }}
          btnLabel={intl.formatMessage({ id: 'View', defaultMessage: 'View' })}
        />
      </LpCtCertCardOverride>
    </WidgetCard>
  )
}

const LPCTCardWrapper = (props, configId = '') => {
  return (
    <WidgetCard dataPermission={configId}>
      <LpCtCardOverride status={props.state}>
        <LPCTCard {...(props as LPCTCardProps)} />
      </LpCtCardOverride>
    </WidgetCard>
  )
}

const CTWidgetCardLoader = (configId = '') => {
  return (
    <WidgetCard dataPermission={configId}>
      <LpCtCardOverride>
        <CTWidgetLoader></CTWidgetLoader>
      </LpCtCardOverride>
    </WidgetCard>
  )
}

const CTWidgetConfig = (id, configId) => {
  return {
    id,
    order: id,
    component: CTWidgetCardLoader(configId),
    configId,
  }
}

const DashboardWidgetSection: React.FC<DashboardWidgetSectionProps> = ({
  LearningPaths,
  Achievements,
  isLoading,
  hasError,
}) => {
  const intl = useIntl()
  const { studentProfile, activeMaterial } = useSelector(({ userProfile, permissions, dashboard }: StoreProps) => {
    const studentProfile = new PortalStudentProfile(userProfile.info?.StudentProfile?.StudentProfileInfo)
    const activeMaterial = dashboard?.activeMaterial
    return {
      studentProfile,
      activeMaterial,
      userPermissions: permissions.permissions,
    }
  })
  const orderedWidgets: Widget[] = orderBy(PortalsWidgets, ['order'])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [getCTState, { data, loading, btnLoading, error }] = useCTCardState()
  const selectedLP = activeMaterial
  const { getConfigByProgramType, getPermissionByProgramType } = useCTPermission()

  const program = studentProfile?.getProgramByMaterialId(activeMaterial ?? '')

  /**
   * You Might Not Need an Effect
   * https://react.dev/learn/you-might-not-need-an-effect
   */

  /**
   * Lets determine here if the selectedLP has Curriculum Test
   * if it has CT materials then we add CT Loader to widgets
   */

  const getCTWidgetInfo = (w: Widget[]) => {
    const widgets = [...w]
    let materialName = ''
    let isCT = false
    let path: PortalLearningPath | undefined = undefined

    if (selectedLP) {
      /**
       * Here in the dashboard page, we only need to display the core material
       * so if valid path is either elective or curriculum test
       * then we need to get the core material
       */
      let validPath = LearningPaths.getLearningPathById(selectedLP)
      if (validPath?.IsElective || validPath?.IsCTMaterial) {
        if (validPath?.MaterialCore?.LPID) {
          validPath = LearningPaths.getLearningPathById(validPath?.MaterialCore?.LPID)
        }
      }

      const programType = validPath?.Material?.ProgramType
      const ctFlag = getPermissionByProgramType(programType)

      /**
       * If it has CT entry point then we add CT loader widget
       * and eventually show CT widget if data is available
       */
      if (validPath && validPath.CtEntryPoint && ctFlag) {
        path = validPath
        materialName = validPath?.Material?.MaterialName ?? ''
        isCT = true

        const allLoading = isLoading || loading

        if (allLoading && !hasError) {
          widgets.splice(1, 0, CTWidgetConfig(110, getConfigByProgramType(programType)))
        } else if (!allLoading && !hasError && data) {
          const props = Object.assign({}, data, { materialName })
          const LPCTCardWidget = {
            id: 100,
            order: 100,
            component: LPCTCardWrapper(props, getConfigByProgramType(programType)),
            configId: getConfigByProgramType(programType),
          }
          widgets.splice(1, 0, LPCTCardWidget)
        } else {
          widgets.splice(1, 0)
        }
      }
    }

    return { widgets, materialName, isCT, path }
  }

  /**
   * if it has Certificate then we add Certificate Loader to widgets
   */
  const getCertificateWidgetInfo = (widgets: Widget[]) => {
    const w = [...widgets]

    if (selectedLP) {
      const validPath = LearningPaths.getLearningPathById(selectedLP)
      const programType = validPath?.Material?.ProgramType

      /**
       * Show certificate loader widget
       */
      if (isLoading && !hasError) {
        w.splice(2, 0, CTWidgetConfig(111, getConfigByProgramType(programType)))
      } else if (!isLoading && !hasError) {
        const achievements = orderBy(
          Achievements.filter(
            (Achievement) =>
              Achievement.achievementInfo?.Type === CertificateTypes.ACHIEVEMENT ||
              Achievement.achievementInfo?.Type === CertificateTypes.PARTICIPATION
          ),
          (achievement: AchievementInfos) => achievement.achievementInfo?.Type
        )

        const achievement = achievements.find(
          (Achievement) => Achievement.achievementInfo?.Registration?.Id === validPath?.Material?.RegistrationId
        )

        const hasAchievementRecords = Boolean(Achievements.length) && Boolean(achievement)

        if (hasAchievementRecords && achievement && achievement.achievementInfo && achievement.achievementInfo?.Type) {
          const component = {
            [CertificateTypes.PARTICIPATION]: LPParticipationCert,
            [CertificateTypes.ACHIEVEMENT]: LPCertCard,
          }
          const LPCertWidget = {
            id: 101,
            order: 101,
            component: component[achievement?.achievementInfo?.Type](
              intl,
              studentProfile,
              getConfigByProgramType(programType)
            ),
            configId: getConfigByProgramType(programType),
          }
          w.splice(2, 0, LPCertWidget)
        } else {
          w.splice(2, 0)
        }
      } else {
        w.splice(2, 0)
      }
    }

    return w
  }

  const widgetInfo = getCTWidgetInfo(orderedWidgets)
  let widgets = widgetInfo.widgets
  const isCT = widgetInfo.isCT
  const path = widgetInfo.path

  widgets = getCertificateWidgetInfo(widgets)

  if (
    program &&
    PROGRAM_TYPES.ON_DEMAND.indexOf(program?.ProgramTypeDisplay ?? '') >= 0 &&
    !program?.IsGroupConversation &&
    !studentProfile.HasBoD1on1Registration
  ) {
    widgets = widgets.filter((widget) => ![1, 4].includes(widget.id))
  }

  if (studentProfile?.student?.RegistrationWrappers?.every((r) => r?.RegistrationInfo?.Status === 'Cancelled')) {
    widgets = widgets.filter((widget) => ![1, 4].includes(widget.id))
  }

  /**
   * Since we are fetching data from API to
   * synchronize with external systems
   * we need to use use effect
   */
  useEffect(() => {
    if (path && isCT && !hasError) {
      getCTState({
        username: studentProfile.student.RecordId,
        url: path?.CtEntryPoint?.NonCultureUnits?.[0]?.Activities?.[0]?.Url || '',
        registration: path?.CtEntryPoint?.Material?.FromRegistration,
        learningPath: path,
      })
    }
  }, [isCT, path])

  useEnsureNoDisplayedModal(() => {
    if (error && isCT) {
      setOpenErrorModal(true)
    }
  }, [error])

  return (
    <Row>
      {widgets.map(({ id, component, configId }) => (
        <RoleBasedView key={id} configId={configId}>
          {component}
        </RoleBasedView>
      ))}
      {isCT && (
        <>
          <LoadingModal
            open={btnLoading}
            onClose={() => {}}
            title={`${intl.formatMessage({ id: 'Please wait a moment' })}.`}
          />
          <WarningModal open={openErrorModal} onClose={() => setOpenErrorModal(false)} anApiError noButtons />
        </>
      )}
    </Row>
  )
}

export default React.memo(DashboardWidgetSection)
