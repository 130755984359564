import styled, { css } from 'styled-components'
import { Colorable, WidgetContentProp } from './interface'

const getIconColors = (bgColor: string, svgColor: string) => css`
  background-color: ${({ theme }) => theme.palette[bgColor]};

  svg {
    color: ${({ theme }) => theme.palette[svgColor]};
  }
`

export const IconCircle = styled.span<Colorable>`
  padding: 4px;
  border-radius: ${({ theme }) => theme.layout.xxxl};

  svg {
    font-size: 16px;
  }

  ${getIconColors('blueGray60', 'blueGray10')}

  ${({ color }) => color === 'success' && getIconColors('green100', 'charcoal')}

  ${({ color }) => color === 'info' && getIconColors('blue100', 'white')}

  ${({ color }) => color === 'warning' && getIconColors('warning100', 'white')}

  ${({ color }) => color === 'error' && getIconColors('danger100', 'white')}

  ${({ color }) => color === 'pending' && getIconColors('purple100', 'white')}
`

export const List = styled.ul``

export const ListItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  button {
    margin-left: auto;
    flex-shrink: 0;
  }
`

export const ListItem = styled.li<{ bordered?: boolean }>`
  display: flex;
  align-items: center;
  padding: 2px 0;

  a {
    cursor: pointer;
  }

  &.no-padding {
    padding: 0 !important;
  }

  .grayed-icon {
    svg {
      color: ${({ theme }) => theme.palette.blueGray60};
    }
  }

  .room-to-be-confirmed {
    svg {
      color: ${({ theme }) => theme.colors.brandPrimary} !important;
      font-size: ${({ theme }) => theme.fontSize.xs} !important;
      margin-left: ${({ theme }) => theme.space.xxs} !important;
    }
  }
  ${({ bordered }) =>
    bordered &&
    css`
      display: flex;
      padding-top: ${({ theme }) => theme.space.xs};
      padding-bottom: ${({ theme }) => theme.space.xs};
      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.palette.gray40};
      }
    `}
`

export const Card = styled.div`
  width: 100%;
  height: 296px;
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.elevation.sm};
  position: relative;

  .no-decoration {
    text-decoration: none;
  }
`

export const Header = styled.div<{ headerColor?: string; withCountdown?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.gray40}`};

  &.no-icon {
    ${({ theme, withCountdown }) => `
      padding: ${theme.space.md};
      ${withCountdown ? `padding-bottom: 7px;` : ''}
    `};
  }

  ${({ headerColor }) =>
    headerColor &&
    css`
      border-bottom: none;
      background-color: ${({ theme }) => theme.colors[headerColor]};
      color: ${({ theme }) => theme.colors[`${headerColor}Contrast`]};
    `}
`

export const HeaderIcon = styled.div<Colorable>`
  padding: ${({ theme }) => theme.space.md};
  margin: ${({ theme }) => theme.space.sm};
  border-radius: ${({ theme }) => theme.layout.xxxl};
  ${getIconColors('blueGray10', 'blueGray60')}

  ${({ color }) => color === 'success' && getIconColors('success10', 'success100')}

  ${({ color }) => color === 'info' && getIconColors('blue10', 'blue100')}

  ${({ color }) => color === 'warning' && getIconColors('warning10', 'warning100')}

  ${({ color }) => color === 'error' && getIconColors('danger10', 'danger100')}
`

export const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;
`

const footerHeight = '56px'

export const Content = styled.div<WidgetContentProp>`
  width: 100%;
  height: calc(215px - ${footerHeight});
  overflow-y: auto;
  padding: ${({ theme }) => `0 ${theme.space.sm}`};

  ${({ footerless }) =>
    footerless &&
    `
    height: 228px;
  `}

  ${({ contentColor }) =>
    contentColor &&
    css`
      background-color: ${({ theme }) => theme.colors[contentColor]};
    `}

  ${({ paletteColor }) =>
    paletteColor &&
    css`
      background-color: ${({ theme }) => theme.palette[paletteColor]};
    `}
`

export const Footer = styled.div<{ multipleButtons?: boolean }>`
  padding: ${({ theme }) => theme.space.sm};
  width: 100%;
  height: ${footerHeight};
  position: absolute;
  bottom: 0;
  display: flex;

  button {
    width: 100%;
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
    padding-left: 0;
    padding-right: 0;
    text-decoration: none;

    ${({ multipleButtons }) =>
      multipleButtons &&
      css`
        &:first-child {
          margin-right: ${({ theme }) => theme.space.xxs};
        }

        &:last-child {
          margin-left: ${({ theme }) => theme.space.xxs};
        }
      `}
  }

  button {
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
    padding-left: 0;
    padding-right: 0;
  }
`
